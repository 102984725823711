/* eslint-disable dot-notation ,radix */
import { useState } from 'react';
import { models } from 'powerbi-client';
import { isEmpty } from 'modules/common/helpers/object';
import { formatDate } from 'modules/dashboard/functions';
import { DATE_PATTERN, TIME_PATTERN } from 'modules/common/constants/date-range';
import { Grid, IconButton, Typography } from '@mui/material';
import { Loader } from 'modules/common/components';
import DownloadIcon from '@mui/icons-material/Download';
import NoDataDialog from 'modules/dashboard/components/tab-container/export-visual-data/components/no-data';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/common/auth/selectors';
import * as XLSX from 'xlsx';
import { selectHotelName } from 'modules/dashboard/selectors';

/**
 * Export Data component to download visual data in format
 * @returns
 */
const ExportToExcel = ({ report, isLoaded, fileName }) => {
  //
  const currentUser = useSelector(selectAuthUser);
  const selectedHotel = useSelector(selectHotelName);
  //
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isDialog, setIsDialog] = useState(false);
  //
  const resetInitData = () => {
    setLoading(false);
    setIsDialog(true);
  };
  //
  const excelNumberCellTransforms = {
    TO_INTEGER: (cell) => ({
      ...cell,
      t: 'n',
    }),
    TO_TWO_DECIMAL: (cell) => ({
      t: 'n',
      v: parseFloat(cell['v']).toFixed(2),
      z: '0.00',
    }),
    TO_PERCENTAGE: (cell) => ({
      t: 'n',
      // eslint-disable-next-line no-nested-ternary
      v: cell.v ? (cell.v === 0 ? 0 : parseFloat(cell.v)) : '',
      z: '0.00%',
    }),
  };

  const extractAllMetaFromPBI = async (visualComponents) => {
    let detailsList = [];
    detailsList = await Promise.all(
      visualComponents.map(async (component) => {
        let data = await component.exportData(models.ExportDataType.Summarized);
        data = data.data.split('\r\n').filter((cmpnt) => cmpnt.includes(':'));
        return data[0];
      })
    );

    return detailsList;
  };
  //
  const extractColumnNameFromCell = (cell) =>
    cell
      .split('')
      .filter((char) => char.charCodeAt(0) <= 90 && char.charCodeAt(0) >= 65)
      .join('');

  const exportPbiData = async () => {
    setLoading(true);
    if (!isEmpty(report) && isLoaded) {
      //
      try {
        const pages = await report.getPages();
        const currentPage = pages.filter((page) => page.isActive)[0];
        const visuals = await currentPage.getVisuals();
        //
        const visualDetailsComponents = visuals.filter((visual) => visual.type === 'multiRowCard');
        let visualMeta = await extractAllMetaFromPBI(visualDetailsComponents).then(
          (metaArray) => metaArray
        );
        //
        const visual = visuals.filter((v) => v.type === 'tableEx')[0];
        const result = await visual.exportData(models.ExportDataType.Summarized);
        //
        // Process meta data
        visualMeta = visualMeta.join(',').replaceAll(',', '\r\n').replaceAll(':', ',');
        //
        let workbookData = `Username, ${currentUser?.username}\r\n`;
        workbookData += `Report Generation Date, ${formatDate(
          new Date(),
          DATE_PATTERN
        )} ${formatDate(new Date(), TIME_PATTERN)}\r\n`;
        workbookData += `Hotel Name, ${selectedHotel?.label}\r\n`;
        workbookData += `${visualMeta} \r\n\r\n`;

        const workbook = XLSX.utils.book_new();

        const aoa = result?.data.split('\r\n').map((row) => row.split(','));
        const worksheet = XLSX.utils.aoa_to_sheet(aoa);

        // update the sheet with csv header
        const updatedSheet = XLSX.read(workbookData + XLSX.utils.sheet_to_csv(worksheet), {
          type: 'string',
        }).Sheets['Sheet1'];

        // range where the actual data
        // header takes up 10 rows appr. therefore actual table with data start from around row 11
        const tableRef = updatedSheet['!ref'].split(':')[1];

        const tableHeight = tableRef
          .split('')
          .filter((char) => !(char.charCodeAt(0) <= 90 && char.charCodeAt(0) >= 65))
          .join('');

        // transform cells
        // comment this section to get the cell ranges and then uncomment back to include transformations
        const ifAllValuesInColumnInteger = {};
        Object.keys(updatedSheet).forEach((cell) => {
          const currentCell = updatedSheet[cell];
          if (currentCell.t !== 's' && currentCell.w) {
            if (currentCell?.w.includes('%')) {
              updatedSheet[cell] = excelNumberCellTransforms.TO_PERCENTAGE(currentCell);
            } else if (currentCell.w.includes('.')) {
              updatedSheet[cell] = excelNumberCellTransforms.TO_TWO_DECIMAL(currentCell);
            }
            // for int values check the other values in the same column to check if it should have decimal places or not
            else if (Number.isInteger(parseFloat(currentCell.w))) {
              // check if all values are integer
              // if table start from row 10
              let isAllInteger = ifAllValuesInColumnInteger[extractColumnNameFromCell(cell)];
              if (isAllInteger === undefined) {
                isAllInteger = true;
                for (let i = 12; i < parseInt(tableHeight); i += 1) {
                  if (!Number.isInteger(updatedSheet[`${extractColumnNameFromCell(cell)}${i}`]?.v)) {
                    isAllInteger = false;
                  }
                }
                ifAllValuesInColumnInteger[extractColumnNameFromCell(cell)] = isAllInteger;
              }

              // do the right transformation
              if (isAllInteger) {
                updatedSheet[cell] = excelNumberCellTransforms.TO_INTEGER(currentCell);
              } else {
                updatedSheet[cell] = excelNumberCellTransforms.TO_TWO_DECIMAL(currentCell);
              }
            }
          }
        });

        if (!updatedSheet['!cols']) updatedSheet['!cols'] = [];
        // eslint-disable-next-line arrow-body-style
        const columnWidths = aoa[0].map((_, colIndex) => {
          // Get the max length of the text in this column
          return Math.max(...aoa.map((row) => (row[colIndex] ? row[colIndex].length : 0)));
        });

        // pad the cells to adjust cell sizes to show full text
        updatedSheet['!cols'] = columnWidths.map((width) => ({ wch: width + 3 }));

        // Convert worksheet back to XLSX format and write to file
        XLSX.utils.book_append_sheet(workbook, updatedSheet);
        XLSX.writeFile(workbook, `${fileName}_${formatDate(new Date(), DATE_PATTERN)}.xlsx`, {
          cellStyles: true,
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
        setMessage('Something went wrong', resetInitData());
      }
    } else {
      setMessage('Visual is still loading', resetInitData());
    }
  };
  //
  return (
    <Loader loading={loading}>
      <Grid container direction="row" alignItems="center">
        <Typography
          sx={{
            mr: 1,
          }}
        >
          Export Data
        </Typography>
        <IconButton
          size="small"
          onClick={() => exportPbiData()}
          sx={{
            '& .MuiSvgIcon-root': {
              width: '1.25rem',
              height: '1.25rem',
            },
          }}
        >
          <DownloadIcon />
        </IconButton>
      </Grid>
      <NoDataDialog open={isDialog} onClose={() => setIsDialog(false)} message={message} />
    </Loader>
  );
};
//
export default ExportToExcel;
