import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'modules/dashboard/slice';
import { SLICE } from 'modules/dashboard/constants';
//
const selectDomain = (state) => state[SLICE.DASHBOARD] || initialState;
/**
 * Getting loader state to dashboard component
 */
export const selectLoader = createSelector([selectDomain], (state) => state.loading);
/**
 * Getting is session timed out state for dashboard component
 */
export const selectIsSessionTimedOut = createSelector(
  [selectDomain],
  (state) => state.isSessionTimedOut
);
/**
 * Getting embed token state to dashboard component
 */
export const selectEmbedToken = createSelector([selectDomain], (state) => state.embedTokenDetails);
/**
 * Getting selected hotel name state to dashboard component
 */
export const selectHotelName = createSelector([selectDomain], (state) => state.hotelName);
/**
 * Getting visuals state to dashboard component
 */
export const selectVisuals = createSelector([selectDomain], (state) => state.visuals?.results);
/**
 * Getting visual filters state to dashboard component
 */
export const selectVisualFilters = createSelector(
  [selectDomain],
  (state) => state.visualFilters?.results
);
/**
 * Getting selected reportType state for dashboard component
 */
export const selectReportType = createSelector([selectDomain], (state) => state?.reportType);
/**
 *  Getting embed token expiry state to dashboard component
 */
export const selectEmbedTokenExpiry = createSelector(
  [selectDomain],
  (state) => state.embedTokenDetails?.expiry
);
/**
 * Getting dynamic utm breakdown state for dashboard component
 */
export const selectUtmBreakdownDetails = createSelector(
  [selectDomain],
  (state) => state.utmBreakdown
);
/**
 *  Getting hotel list state to dashboard component
 */
export const selectHotelDropdownList = createSelector([selectDomain], (state) => state.hotelList);
/**
 *  Getting hotel list state to dashboard component
 */
export const selectHotelsList = createSelector([selectDomain], (state) => state.hotelList?.results);
/**
 *  Getting hotel details by hotel id state to dashboard component
 */
const getFirstParam = (_, param) => param;
export const selectHotelById = createSelector(
  [selectHotelsList, getFirstParam],
  (hotelList, hotelId) => hotelList?.filter((hotel) => hotel?.id === hotelId)[0]
);
/**
 *  Getting latest updated hotel list state to dashboard component
 */
export const selectLatestUpdatedHotelsList = createSelector(
  [selectDomain],
  (state) => state.latestUpdatedHotelList?.results
);
/**
 *  Getting pms sync date of the hotel state to dashboard component
 */
export const selectPmsDate = createSelector([selectDomain], (state) => state.pmsDate);
/**
 *  Getting pms sync date of the hotel state to dashboard component
 */
export const selectGroupPmsDates = createSelector([selectDomain], (state) => state.groupPmsDates);
/**
 *  Getting pms sync state to dashboard component
 */
export const selectPmsSync = createSelector([selectDomain], (state) => state.pmsSync);

/**
 * Getting is signed in state for dashboard component
 */
export const selectIsSignedIn = createSelector([selectDomain], (state) => state?.isSignedIn);
/**
 *Getting data last updated date for dashboard component
 */
export const selectDataLastUpdated = createSelector(
  [selectDomain],
  (state) => state?.lastUpdatedDate
);
/**
 *  Getting hotel changed state
 */
export const selectHotelChangeState = createSelector([selectDomain], (state) => state.hotelChange);
/**
 *  Getting tab changed state
 */
export const selectTabChangeState = createSelector([selectDomain], (state) => state.tabChange);
/**
 *  Getting is demo user state to the component
 */
export const selectIsDemoUser = createSelector([selectDomain], (state) => state.isDemoUser);
/**
 *  Getting logged in user role state to the component
 */
export const selectUserRole = createSelector([selectDomain], (state) => state.userRole);
/**
 *  Getting tab access state to the component
 */
export const selectTabAccess = createSelector([selectDomain], (state) => state.tabAccess);
/**
 *  Getting is user configuration not found state to the component
 */
export const selectIsUserConfigurationNotExist = createSelector(
  [selectDomain],
  (state) => state.isUserConfigurationNotExist
);
/**
 * Getting is token revoked state for dashboard component
 */
export const selectTokenRevocation = createSelector(
  [selectDomain],
  (state) => state.isTokenRevoked
);
/**
 *  Getting tab value
 */
export const selectTabValue = createSelector([selectDomain], (state) => state.tabValue);
/**
 *  Getting generated current url
 */
export const selectCurrentUrl = createSelector([selectDomain], (state) => state.currentUrl);
/**
 *  Getting saved command filters state to dashboard component
 */
export const selectSavedCommandFilters = createSelector(
  [selectDomain],
  (state) => state.commandUrlFilterList
);
/**
 *  Getting saved command filter drop down list state to dashboard component
 */
export const selectCommandFilterDropdownList = createSelector(
  [selectDomain],
  (state) => state.commandUrlFilterDropdownList
);
/**
 *  Getting saved command filter loader state to dashboard component
 */
export const selectCommandFilterLoader = createSelector(
  [selectDomain],
  (state) => state.commandFilterLoader
);
/**
 * Getting dynamic breakdown state for dashboard component
 */
export const selectBreakdownDetails = createSelector([selectDomain], (state) => state.breakdown);
/**
 * Getting dynamic forecast filter state for dashboard component
 */
export const selectForecastFilter = createSelector(
  [selectDomain],
  (state) => state.forecastFilters
);
/**
 * Getting target date state to dashboard component
 */
export const selectTargetDate = createSelector([selectDomain], (state) => state.targetDate);
/**
 *  Getting pace horizon state to dashboard component
 */
export const selectPaceHorizon = createSelector(
  [selectDomain],
  (state) => state.summaryPaceHorizon
);
/**
 *  Getting pace variable state to dashboard component
 */
export const selectPaceVariable = createSelector(
  [selectDomain],
  (state) => state.summaryPaceVariable
);
/**
 *  Getting pace booking date state to dashboard component
 */
export const selectPaceBookingDate = createSelector(
  [selectDomain],
  (state) => state.paceBookingDate
);
/**
 *  Getting pace booking style state to dashboard component
 */
export const selectPaceBookingStyle = createSelector(
  [selectDomain],
  (state) => state.paceBookingStyle
);
/**
 *  Getting pace OTB state to dashboard component
 */
export const selectPaceOtb = createSelector([selectDomain], (state) => state.paceOtb);

/**
 *  Getting trend target date state to dashboard component
 */
export const selectTrendTargetDate = createSelector(
  [selectDomain],
  (state) => state.trendTargetDate
);
/**
 *  Getting trend booking date state to dashboard component
 */
export const selectTrendBookingDate = createSelector(
  [selectDomain],
  (state) => state.trendBookingDate
);
/**
 *  Getting trend OTB state to dashboard component
 */
export const selectTrendOtb = createSelector([selectDomain], (state) => state.trendOtb);
/**
 *  Getting trend booking style state to dashboard component
 */
export const selectTrendBookingStyle = createSelector(
  [selectDomain],
  (state) => state.trendBookingStyle
);
/**
 *  Getting weekly trend tabs start target date state to dashboard component
 */
export const selectWeeklyTrendStartDate = createSelector(
  [selectDomain],
  (state) => state.weeklyTrendStartDate
);
/**
 *  Getting weekly trend tabs end target start date state to dashboard component
 */
export const selectWeeklyTrendEndDate = createSelector(
  [selectDomain],
  (state) => state.weeklyTrendEndDate
);
/**
 *  Getting hotel group list state to dashboard component
 */
export const selectHotelGroupDropdownList = createSelector(
  [selectDomain],
  (state) => state.hotelGroupsDropdownList
);
/**
 *  Getting hotel group list state to dashboard component
 */
export const selectHotelGroupsList = createSelector(
  [selectDomain],
  (state) => state.hotelGroupsList
);
/**
 *  Getting existed hotel group state to dashboard component
 */
export const selectExistedHotelGroup = createSelector(
  [selectDomain],
  (state) => state.existedHotelGroup
);
/**
 *  Getting selected hotel group name state to dashboard component
 */
export const selectHotelGroupName = createSelector([selectDomain], (state) => state.hotelGroupName);
/**
 *  Getting selected hotel group query state to dashboard component
 */
export const selectHotelGroupQuery = createSelector(
  [selectDomain],
  (state) => state.hotelGroupQuery
);
/**
 *  Getting selected hotel group query state to dashboard component
 */
export const selectEnableHotelGroups = createSelector(
  [selectDomain],
  (state) => state.enableHotelGroups
);
/**
 *  Getting selected summary forecast state to dashboard component
 */
export const selectSummaryForecastFilter = createSelector(
  [selectDomain],
  (state) => state.summaryForecastFilter
);
/**
 *  Getting selected landing page state to dashboard component
 */
export const selectLandingPage = createSelector([selectDomain], (state) => state.landingPage);
/**
 *  Getting selected hotel groups state to dashboard component
 */
export const selectHotelGroups = createSelector([selectDomain], (state) => state.hotelGroups);
/**
 * Getting notification list
 */
export const selectNotifications = createSelector([selectDomain], (state) => state.notifications);
/**
 * Getting merged hotel list
 */
export const selectMergedHotelList = createSelector(
  [selectDomain],
  (state) => state.mergedHotelList
);
/**
 * Getting favorite hotel list
 */
export const selectFavoriteHotelList = createSelector(
  [selectDomain],
  (state) => state.mergedHotelList
);
/**
 * Getting non favorite hotel list
 */
export const selectNonFavoriteHotelList = createSelector(
  [selectDomain],
  (state) => state.mergedHotelList
);
/**
 *  Getting assigned hotel groups state to the component
 */
export const selectAssignedHotelGroups = createSelector(
  [selectDomain],
  (state) => state.assignedHotelGroups
);
/**
 *  Getting all hotel list state to dashboard component
 */
export const selectAllHotelList = createSelector([selectDomain], (state) => state.allHotelList);
/**
 *  Getting is saved command filter loaded state to dashboard component
 */
export const selectIsSavedCommandFilterLoaded = createSelector(
  [selectDomain],
  (state) => state.IsSavedCommandFilterLoaded
);
/**
 *  Getting currency filter state to dashboard component
 */
export const selectCurrencyFilter = createSelector([selectDomain], (state) => state.currencyFilter);
/**
 *  Getting local currency state to dashboard component
 */
export const selectLocalCurrency = createSelector([selectDomain], (state) => state.localCurrency);
/**
 *  Getting local currency list state to dashboard component
 */
export const selectCurrencyList = createSelector([selectDomain], (state) => state.currencyList);
/**
 *  Getting workbookDateType to dashboard component
 */
export const selectWorkbookDateType = createSelector(
  [selectDomain],
  (state) => state.workbookDateType
);
/**
 * Getting selected hotel forecast availability state to dashboard component
 */
export const selectsHotelForecast = createSelector(
  [selectDomain],
  (state) => state.isHotelForecast
);
/**
 * Getting selected hotel forecast disabled text state to dashboard component
 */
export const selectForecastDisabledText = createSelector(
  [selectDomain],
  (state) => state.hotelForecastDisabledText
);
/**
 *  Getting predefined hotel groups state to the component
 */
export const selectPredefinedHotelGroups = createSelector(
  [selectDomain],
  (state) => state.predefinedHotelList
);
/**
 *  Getting is Hotel Deleted state to the component
 */
export const SelectIsHotelDeleted = createSelector([selectDomain], (state) => state.isHotelDeleted);
