import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { isEmpty } from 'modules/common/helpers/object';
import { useEffect, useState } from 'react';
import { ENVIRONMENT } from 'config';
import { selectAuthUser, selectSessionId } from 'modules/common/auth/selectors';
import { useSelector } from 'react-redux';
import { retrieveVisualLoadDuration } from 'modules/common/utils/filter-visual-mapper';
import faroLog, { faro } from 'modules/common/utils/faro';
import { selectCurrencyFilter } from '../../../selectors';

/**
 * Power BI visual component
 * @param {String} cssClassName - Class name for the visual
 * @param {String} accessToken - Power bi access token
 * @param {String} embedUrl - Power bi embed url
 * @param {String} id - Power bi report id
 * @param {String} pageName - Power bi page name
 * @param {Array} filters - List of filters to be applied for the visual
 * @param {Function} setPBIReport - Function to set loaded report object
 * @param {Function} setIsLoaded - Function to set report loaded state
 * @returns
 */

const VisualCard = (props) => {
  const {
    cssClassName,
    accessToken,
    embedUrl,
    visualName,
    id,
    pageName,
    filters,
    reportName,
    pageDisplayName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    setIsRendered = () => {},
  } = props;
  const [embeddedReport, setEmbeddedReport] = useState();
  // global selectors
  const user = useSelector(selectAuthUser);
  const sessionId = useSelector(selectSessionId);
  const currencyFilter = useSelector(selectCurrencyFilter);
  //
  const [reportInstance, setReportInstance] = useState();
  let visualRenderedCount = new Map();
  //
  const handleLogging = (message, level, visualtype = null) => {
    if (ENVIRONMENT.PBI_EMBED_DEBUG_MODE?.toLowerCase() === 'true') {
      console.log(message);
    }
    faro.api.setUser({ username: user?.username || 'undefined' });
    faroLog(message, level.toUpperCase(), { sessionId, visualtype });
  };

  embeddedReport?.off('visualRendered');
  embeddedReport?.off('rendered');
  embeddedReport?.off('loaded');
  embeddedReport?.off('error');

  embeddedReport?.on('rendered', (event) => {
    const currentData = new Date();
    //
    const dispatchDuration = retrieveVisualLoadDuration(currentData);
    //
    const logMessage = `${currentData.toISOString()} - Event : rendered | Visual : ${visualName} | Report : ${reportName} | Page : ${pageDisplayName} | Visual Rendered Count : ${visualRenderedCount.get(
      `${visualName}_${reportName}_${pageDisplayName}`
    )} | Duration between filter dispatch : ${dispatchDuration}`;
    //
    handleLogging(logMessage, 'info', 'rendered');
    if (ENVIRONMENT.PBI_EMBED_DEBUG_MODE?.toLowerCase() === 'true') {
      visualRenderedCount = new Map();
    }
    if (event.target.classList.contains('loader')) {
      event.target.classList.remove('loader');
    }
    //
    setIsRendered(true);
  });

  embeddedReport?.on('loaded', () => {
    const logMessage = `${new Date().toISOString()} - Event : loaded | Visual : ${visualName} | Report : ${reportName} | Page : ${pageDisplayName}`;
    handleLogging(logMessage, 'info', 'loaded');
    setIsLoaded(true);
  });

  embeddedReport?.on('error', (event) => {
    const logMessage = `${new Date().toISOString()} - Event : error | Visual : ${visualName} | Report : ${reportName} | Page : ${pageDisplayName} | Message : ${
      event?.detail?.detailedMessage
    }`;
    handleLogging(logMessage, 'error');
  });

  embeddedReport?.on('visualRendered', () => {
    const logMessage = `${new Date().toISOString()} - Event : visualRendered | Visual : ${visualName} | Report : ${reportName} | Page : ${pageDisplayName}`;
    handleLogging(logMessage, 'info', 'visualRendered');
    if (ENVIRONMENT.PBI_EMBED_DEBUG_MODE?.toLowerCase() === 'true') {
      visualRenderedCount.set(
        `${visualName}_${reportName}_${pageDisplayName}`,
        visualRenderedCount.get(`${visualName}_${reportName}_${pageDisplayName}`)
          ? visualRenderedCount.get(`${visualName}_${reportName}_${pageDisplayName}`) + 1
          : 1
      );
    }
  });
  // Triggered when the filters, reportInstance, or currencyFilter values change
  useEffect(() => {
    if (reportInstance && filters && currencyFilter) {
      setIsRendered(false);
      // Combine tab level filters with the currency filter
      // Note: The currency filter is added directly since it is global to the application
      const updatedFilter = [...filters, currencyFilter];
      // Update the report instance with the new filters
      reportInstance
        ?.updateFilters(models.FiltersOperations.ReplaceAll, updatedFilter)
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    }
  }, [filters, reportInstance]);
  //
  useEffect(() => {
    // update the timestamp in the localstorage to calculate the time taken to complete the visual render on each rendered event
    localStorage.setItem('visualTimeDifference', JSON.stringify(new Date().toISOString()));
  }, [pageDisplayName]);
  //
  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken,
        embedUrl,
        id,
        pageName,
        ...(isEmpty(reportInstance?.config?.filters) && { filters: [...filters, currencyFilter] }),
        settings: {
          displayOption: models.DisplayOption.FitToPage,
          visualRenderedEvents: true,
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
          background: models.BackgroundType.Transparent,
        },
      }}
      cssClassName={cssClassName}
      getEmbeddedComponent={(report) => {
        setPBIReport(report);
        setReportInstance(report);
        setEmbeddedReport(report);
      }}
    />
  );
};
//
export default VisualCard;
